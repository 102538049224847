/**
 * This class adjusts the part size of the upload based on
 * the success/failure of the previous uploads.
 *
 * It is based on the binary search algorithm,
 * but with some adjustments built in to defend against noises.
 */
export default class UploadPartSizeAdjuster {
  /**
   * Current part size multiple.
   * Not starting from the exact center to be conservative.
   *
   * It will be updated to ({@link UploadPartSizeAdjuster.successMultiple} + {@link UploadPartSizeAdjuster.failureMultiple}) / 2
   * every time.
   */
  partSizeMultiple = window.location.hostname === "localhost" ? 1 : 50;

  /**
   * Constant, minimum range.
   */
  minPartSizeMultiple = 1;
  /**
   * Constant, maximum range.
   */
  maxPartSizeMultiple = 150;

  /**
   * Last known successful part size multiple.
   */
  successMultiple = 1;
  /**
   * Last known failure part size multiple.
   */
  failureMultiple = 150;

  /**
   * Adjustment scale when the success/failure multiple is
   * equal to the current failure/success multiple.
   */
  adjustmentScale = 1.2;

  /**
   * @param {boolean} success
   */
  updatePartSize(success) {
    if (success) {
      this.successMultiple = this.partSizeMultiple;
      if (this.failureMultiple === this.partSizeMultiple) {
        this.failureMultiple = Math.min(
          this.maxPartSizeMultiple,
          Math.max(
            Math.round(this.failureMultiple * this.adjustmentScale),
            this.failureMultiple + 1,
          ),
        );
      }
    } else {
      this.failureMultiple = this.partSizeMultiple;
      if (this.successMultiple === this.partSizeMultiple) {
        this.successMultiple = Math.max(
          this.minPartSizeMultiple,
          Math.min(
            Math.round(this.successMultiple / this.adjustmentScale),
            this.successMultiple - 1,
          ),
        );
      }
    }
    this.partSizeMultiple = Math.floor(
      (this.successMultiple + this.failureMultiple) / 2,
    );
  }
}
